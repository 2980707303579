import React from "react";
import { IonFooter } from "@ionic/react";

var styles = {
  a: {
    padding: "4px"
  },
  divfont: {
    fontSize: "10pt",
    display: "contents"
  }
};

export default function CustomFooter(props) {
  let currentYear = new Date().getFullYear()
  return (
    <>
      <IonFooter
        style={{
          backgroundColor: "white",
          marginBottom: "8px",
          paddingBottom: "8px"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div style={styles.divfont}>
            <a
              href="http://oncor.com/EN/Pages/PrivacyPolicy.aspx"
              style={styles.a}
            >
              Privacy Policy
            </a>
            <a href="http://oncor.com/EN/Pages/Legal.aspx" style={styles.a}>
              Terms Of Use
            </a>
            <p>v1.0.0</p>
          </div>
        </div>
        <div>
          <div style={{ alignSelf: "flex-start" }}></div>
          <div>
            <p
              style={{
                marginTop: "0px",
                marginBottom: "5px",
                textAlign: "center",
                fontSize: "10pt"
              }}
            >
              &copy; {currentYear} Oncor Electric Delivery LLC, All Rights Reserved
            </p>
          </div>
          {/* <p></p> */}
          {/* <div style={{ alignSelf: "flex-end" }}></div> */}
        </div>
      </IonFooter>
    </>
  );
}
